export const eximColumn = [
  {
    Header: "No.",
    accessor: "serialNo",
    width: "60px",
    fixed: true,
    disableFilters: true,
    showCheckbox: false,
  },
  {
    Header: "ITC HS Code",
    accessor: "hsCode",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Product Description",
    accessor: "productDescription",
    width: "350px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Exporter",
    accessor: "exporter",
    width: "300px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Foreign Port",
    accessor: "foreignPort",
    width: "300px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Foreign Country",
    accessor: "foreignCountry",
    width: "250px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Unit",
    accessor: "unit",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Value_FC",
    accessor: "valueFC",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Indian Port",
    accessor: "indianPort",
    width: "250px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Mode",
    accessor: "mode",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Date",
    accessor: "date",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Buyer",
    accessor: "buyer",
    width: "300px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Rate_FC",
    accessor: "rateFC",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "IEC",
    accessor: "iec",
    width: "250px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Exporter Address1",
    accessor: "exporterAddress1",
    width: "350px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Exporter Address2",
    accessor: "exporterAddress2",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Exporter City",
    accessor: "exporterCity",
    width: "250px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Challan No",
    accessor: "challanNo",
    width: "250px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Bill Number",
    accessor: "billNumber",
    width: "250px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Month",
    accessor: "month",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Year",
    accessor: "year",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Currency",
    accessor: "currency",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "FOB",
    accessor: "fob",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Invoice No",
    accessor: "invoiceNo",
    width: "250px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Rate",
    accessor: "rate",
    width: "220px",
    sortable: true,
    filterable: true,
    showCheckbox: false,
  },
];
