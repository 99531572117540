export const stateName = [
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Andaman & Nicobar Islands", label: "Andaman & Nicobar Islands" },
  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  { value: "Assam", label: "Assam" },
  { value: "Bihar", label: "Bihar" },
  { value: "Chhattisgarh", label: "Chhattisgarh" },
  { value: "Haryana", label: "Haryana" },
  { value: "Punjab", label: "Punjab" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "West Bengal", label: "West Bengal" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  { value: "Jammu & Kashmir", label: "Jammu & Kashmir" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Odisha", label: "Odisha" },
  { value: "Uttarakhand", label: "Uttarakhand" },
  { value: "Telangana", label: "Telangana" },
  { value: "Tripura", label: "Tripura" },
  { value: "Sikkim", label: "Sikkim" },
  { value: "Nagaland", label: "Nagaland" },
  { value: "Mizoram", label: "Mizoram" },
  { value: "Meghalaya", label: "Meghalaya" },
  { value: "Lakshadweep", label: "Lakshadweep" },
  { value: "Goa", label: "Goa" },
];
