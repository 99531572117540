import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Space,
  TextInput,
  FileInput,
  Textarea,
  Select,
  Group,
} from "@mantine/core";
import { FileAlert, ExternalLink } from "tabler-icons-react";
import { Button } from "../../../components/index";
import { useForm } from "@mantine/form";
import { randomId } from "@mantine/hooks";
import ReactPlayer from "react-player";
import ImageUpload from "../../../components/ImageUpload/ImageUpload";
import { showNotification } from "@mantine/notifications";
import { uploadImageToS3 } from "../../../helper/helper";

const initialFormValues = {
  title: "",
  description: "",
  image: "",
  action_type: "",
  action_url: "",
  key: randomId(),
};

const actionTypes = [
  { label: "In-App", value: "in-app" },
  { label: "External", value: "external" },
];

interface ImageResult {
  uri: string;
  path: string;
  fileSrc: string;
  // Add other properties if needed
}

function EditAdsForm(props: any) {
  const handleCloseModal = props.handleCloseModal;
  const handleSaveAction = props.handleSaveAction;
  const updateFormData = props.updateFormData;
  const handlePictureChange = props.handlePictureChange;
  const modalType = props.modalType || "add";
  const [actionType, setActionType] = useState<string>("");
  const playerRef = useRef<ReactPlayer | null>(null);
  const [updateFormImage, setUpdateFormImage] = useState<string[]>([]);
  const [imageResult, setImageResult] = useState<ImageResult | null>(null);

  const form: any = useForm({
    clearInputErrorOnChange: true,
    initialValues: { ...initialFormValues },
  });

  useEffect(() => {
    if (updateFormData && modalType === "update") {
      form.setValues(updateFormData);
      form.setFieldValue("image", updateFormData.image);
      setActionType(updateFormData.action_type);
    }
  }, [updateFormData, modalType]);

  useEffect(() => {
    if (updateFormData && modalType === "update") {
      form.setValues({
        ...updateFormData,
      });
    }
  }, [updateFormData, modalType]);

  const imageFileLabels = ["Image 1"];
  const validateImageAspectRatio = (file: File) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const expectedRatio = 2.71; // 100 / 47;
        if (Math.abs(aspectRatio - expectedRatio) < 0.01) {
          resolve(true);
        } else {
          showNotification({
            title: "Image aspect ratio must be 2.71:1",
            message: "please try again",
            autoClose: 3000,
            icon: <FileAlert />,
            color: "red",
          });
        }
      };
      img.onerror = () => {
        reject("Error loading image");
      };
    });
  };

  const handleFileInputChange = async (file: File) => {
    try {
      await validateImageAspectRatio(file);
      handlePictureChange(file, form.values)
        .then((result: any) => {
          setImageResult(result);
        })
        .catch((err: any) => {
          setImageResult(null);
          console.error(err);
        });
    } catch (error) {
      setImageResult(null);
      alert(error);
    }
  };

  const fileInputs = imageFileLabels.map((label, index) => (
    <Grid.Col key={index}>
      <FileInput
        accept="image/png,image/jpeg"
        label="Image"
        required={true}
        onChange={(e) => {
          if (e) handleFileInputChange(e);
        }}
      />
    </Grid.Col>
  ));

  const handleDeleteImage = () => {
    form.setFieldValue("image", "");
  };

  const actionTypeList = actionTypes.map((i) => ({
    value: i.value,
    label: i.label,
  }));

  const handlePictureInputChange = (e: any) => {
    return handlePictureChange(e)
      .then((result: any) => {
        let latestImages = [];
        if (modalType === "add") {
          latestImages = [
            ...form.values.imagesArray,
            {
              url: result.uri,
              src: e,
              path: result.path,
            },
          ].slice(-4);
        }
        form.setFieldValue("image", latestImages);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleActionTypeChange = (newValue: string) => {
    setActionType(newValue);
    form.setFieldValue("action_type", newValue);
  };

  const handleFormSubmit = async (formValues: typeof form.values) => {
    if (imageResult) {
      const uri = imageResult.uri;
      const path = imageResult.path;
      const file = imageResult.fileSrc;
      try {
        const resImageUpload = await uploadImageToS3(uri, file);
        if (resImageUpload) {
          formValues.image = path;
        }
      } catch (error) {
        console.error(`Error processing image: ${error}`);
        // Handle error as needed
      }
    }
    if (
      (formValues.action_type === "in-app" &&
        !formValues.action_url.startsWith("/")) ||
      (formValues.action_type === "external" &&
        !formValues.action_url.startsWith("https://") &&
        !formValues.action_url.startsWith("http://"))
    ) {
      showNotification({
        title: "Invalid Action URL",
        message:
          formValues.action_type === "in-app"
            ? "For in-app, the path should start with /"
            : "For external, the url should start with https:// or http://",
        autoClose: 3000,
        icon: <ExternalLink />,
        color: "red",
      });
      return;
    }
    handleSaveAction(formValues);
    handleCloseModal(false);
    setImageResult(null);
    form.setValues(initialFormValues);
  };

  return (
    <form onSubmit={form.onSubmit(handleFormSubmit)}>
      <Grid gutter="sm">
        <Grid.Col span={12}>
          <TextInput
            required
            label="Title"
            placeholder="eg. Spring Sale"
            {...form.getInputProps("title")}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Textarea
            placeholder="eg. Enjoy upto 50% on all items."
            label="Description"
            required={true}
            autosize
            {...form.getInputProps("description")}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          {modalType === "update" && form.values.image ? (
            <ImageUpload
              key="update-image-upload"
              imageUrl={form.values.image}
              onDelete={handleDeleteImage}
            />
          ) : (
            <>
              <Grid>{fileInputs}</Grid>
            </>
          )}
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            data={actionTypeList}
            label="Action Type"
            placeholder="Select an action type"
            onChange={handleActionTypeChange}
            value={form.values.action_type}
            required
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            required
            label="Action URL"
            placeholder=""
            {...form.getInputProps("action_url")}
          />
        </Grid.Col>
      </Grid>

      <Space h="sm" />
      <Group position="right" mt="md" spacing="md">
        <Button
          disabled={
            !form.values.action_type ||
            !form.values.action_url ||
            !form.values.description ||
            (modalType !== "update" && !imageResult) ||
            (modalType === "update" && !form.values.image && !imageResult) ||
            // (modalType === "update" && !imageResult) ||
            !form.values.title
              ? true
              : false
          }
          type="submit"
        >
          Submit
        </Button>
      </Group>
    </form>
  );
}

export default EditAdsForm;
