import React, { useEffect, useState } from "react";
import { Check } from "tabler-icons-react";
import { Text } from "../../../components/index";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";

import EditAdsForm from "../../../forms/Common/ManageAds";
import PageWrapper from "../../../components/Wrappers/PageWrapper";
import ReactTable from "../../../components/ReactTable/ReactTable";
import {
  getBannerData,
  postBannerData,
  deleteBannerData,
  patchBannerData,
} from "../../../services/ads-management/index";
import { hasAddNewPermission } from "../../../helper/helper";
import APIRequest from "../../../helper/api";

const columns = [
  {
    Header: "No.",
    accessor: "serialNo",
    width: "50px",
    fixed: true,
    disableFilters: true,
    showCheckbox: false,
  },
  {
    Header: "Title",
    accessor: "title",
    width: "300px",
    sortable: true,
    fixed: false,
    disableFilters: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Image URL",
    accessor: "image",
    width: "300px",
    sortable: true,
    fixed: false,
    disableFilters: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Description",
    accessor: "description",
    width: "300px",
    sortable: true,
    fixed: false,
    disableFilters: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Action Type",
    accessor: "action_type",
    width: "200px",
    sortable: true,
    fixed: false,
    disableFilters: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Action URL",
    accessor: "action_url",
    width: "300px",
    sortable: true,
    fixed: false,
    disableFilters: true,
    filterable: true,
    showCheckbox: false,
  },
  {
    Header: "Action",
    accessor: "action",
    width: "100px",
    sortable: false,
    fixed: true,
    disableFilters: true,
    filterable: false,
    showCheckbox: false,
  },
];

const RenderModalContent = (props: any) => {
  const handleCloseModal = props.handleCloseModal;
  const updateFormData = props.updateFormData;
  const handleSaveAction = props.handleSaveAction;
  const variantSelectOptions = props.variantSelectOptions;
  const handlePictureChange = props.handlePictureChange;
  const tagsList = props.tagsList;
  const modalType = props.modalType;

  return (
    <EditAdsForm
      handleCloseModal={handleCloseModal}
      handleSaveAction={handleSaveAction}
      variantSelectOptions={variantSelectOptions}
      handlePictureChange={handlePictureChange}
      updateFormData={updateFormData}
      tagsList={tagsList}
      modalType={modalType}
    />
  );
};

function ManageAds() {
  const [modalOpen, setModalOpen] = useState<any>(false);
  const [modalType, setModalType] = useState<string>("add");
  const [updateFormData, setUpdateFormData] = useState<any>(null);
  const [tableRowData, setTableRowData] = useState<any>([]);
  const [bannerList, setBannerList] = useState<any>([]);

  const handleGetBannerData = async () => {
    const response = await getBannerData();
    if (response) {
      setBannerList(response);
    }
  };

  //to add new or edit the existing row in the table
  const handleSaveAction = async (data: any) => {
    if (data && modalType === "add") {
      const response = await postBannerData(data);

      if (response) {
        handleRefreshCalls();
        showNotification({
          title: "Banner added successfully!",
          message: "",
          autoClose: 2000,
          icon: <Check />,
          color: "green",
        });
      }
    }

    if (data && modalType === "update") {
      const response = await patchBannerData(data);
      if (response) {
        handleRefreshCalls();
        showNotification({
          title: "Banner updated successfully!",
          message: "",
          autoClose: 2000,
          icon: <Check />,
          color: "green",
        });
      }
    }
  };

  // to delete a single row data
  const openDeleteModal = (rowData: any) =>
    openConfirmModal({
      title: "Delete the Banner Data",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this Banner?
          <Text fw={500}>
            Note:This action is destructive and you will have to contact support
            to restore this data.
          </Text>
        </Text>
      ),
      labels: { confirm: "Delete Banner", cancel: "No, don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleDeleteRow(rowData),
    });

  const handleDeleteRow = async (data: any) => {
    const response = await deleteBannerData(data);

    if (response) {
      handleRefreshCalls();
      showNotification({
        title: "Banner deleted successfully!",
        message: "",
        autoClose: 2000,
        icon: <Check />,
        color: "green",
      });
    }
  };

  const handleGenerateSignedUrl = (e: any, name: any, ext: any, form: any) => {
    const FileName = name.replace(/\s+/g, "_");
    const directory = `ads/banner/`; // Constructing the directory parameter
    const c = APIRequest(
      `generate-signed-url?fileName=${FileName}&extension=${ext}&mediaType=image&directory=${directory}`,
      "GET"
    )
      .then((res: any) => {
        if (res) {
          const uri = res.url;
          const path = res.key;
          const fileSrc = e;
          const imageObject = {
            uri,
            fileSrc,
            path,
          };
          return imageObject;
        }
      })
      .catch((error: any) => {
        console.error("Error fetching signed URL:", error);
      });
    return c;
  };

  const handlePictureChange = async (e: any, form: any, locationType: any) => {
    const file = e;
    const extString = file.type;
    const extStringArr = extString.split("/");
    const ext = extStringArr[1];
    const name = `${Math.floor(Date.now() / 1000)}`;
    const result = await handleGenerateSignedUrl(e, name, ext, form);
    return result;
  };

  const handleRefreshCalls = () => {
    handleGetBannerData();
  };

  useEffect(() => {
    handleGetBannerData();
  }, []);

  useEffect(() => {
    if (bannerList) {
      setTableRowData(bannerList);
    }
  }, [bannerList]);

  const actionButtons = [
    {
      label: "Add New",
      color: "gray",
      type: "button",
      onClickAction: () => {
        setModalOpen(true);
        setModalType("add");
      },
    },
  ];
  const conditionalActionButtons = hasAddNewPermission()
    ? actionButtons
    : actionButtons.slice(0, 1);

  return (
    <PageWrapper
      PageHeader={() => null}
      PageAction={() => null}
      modalOpen={modalOpen}
      modalTitle={
        modalType === "add"
          ? "Add Banner Details"
          : modalType === "video-play"
            ? ""
            : "Update Banner Details"
      }
      onModalClose={() => {
        setModalOpen(false);
        setUpdateFormData(null);
      }}
      ModalContent={() => {
        return (
          <RenderModalContent
            handleCloseModal={(bool: boolean) => setModalOpen(bool)}
            handleSaveAction={handleSaveAction}
            handlePictureChange={handlePictureChange}
            updateFormData={updateFormData}
            bannerList={bannerList}
            modalType={modalType}
            modalOpen={modalOpen}
          />
        );
      }}
      modalSize="50%"
    >
      <ReactTable
        data={tableRowData}
        columns={columns}
        actionButtons={conditionalActionButtons}
        onEditRow={(row: any, index: number) => {
          let obj = { ...row };
          const formObj = {
            image: obj.image,
            title: obj.title,
            description: obj.description,
            action_type: obj.action_type,
            action_url: obj.action_url,
            _id: obj._id,
          };
          setUpdateFormData(formObj);
          setModalType("update");
          setModalOpen(true);
        }}
        onDeleteRow={(row: any) => {
          openDeleteModal(row);
        }}
      />
    </PageWrapper>
  );
}

export default ManageAds;
