import APIRequest from "../../helper/api";

export const getBannerData = async () => {
  const response: any = await APIRequest("ad-banner", "GET");
  if (response) {
    return response;
  }
};

export const postBannerData = async (data: any) => {
  const response: any = await APIRequest("ad-banner", "POST", data);
  if (response) {
    return response;
  }
};

export const deleteBannerData = async (data: any) => {
  const response: any = await APIRequest(
    "ad-banner" + "/" + data._id,
    "DELETE"
  );
  if (response) {
    return response;
  }
};

export const patchBannerData = async (data: any) => {
  const response = await APIRequest("ad-banner" + "/" + data._id, "PUT", data);
  if (response) {
    return response;
  }
};
